/**
 * Toolbox types known to the system.
 */
export const toolboxTypes = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  AuthoritySpecificTags: 'Authority Specific Tags',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  DisableSubmission: 'Disable Submission & QA',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  AutomateSearchReorder: 'Automate Search Reorder',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  AutomaticInstantSearches: 'Automatic Instant Searches',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  SendingPlans: 'Pencil Request Plans',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Rule7: 'Rule7',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  UnrefinedData: 'UnrefinedData',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  RaiseAccesswayQueries: 'Raise Accessway Queries?',
};
